import { useQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchLanguages = () => {
  const newEndpoints = endpoints.quotes.translate;
  return http().get(newEndpoints);
};

export function useFetchLanguages(id) {
  return useQuery(["fetchLanguages", id], () => fetchLanguages());
}
