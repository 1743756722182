import Layout from "components/layout";
import QualityAnalysis from "components/QualityTools";
import SEO from "components/seo";

import React from "react";

const QualityTool = () => {
  const title = "Translation Quality Assurance";
  const description =
    "Catch quality issues before delivery with this translation quality assurance tool.  Instantly check fluency, grammar, style, and consistency for better results.";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/tools/translation-quality-assurance"
      />
      <QualityAnalysis />
    </Layout>
  );
};
export default QualityTool;
