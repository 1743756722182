import React, { useEffect, useState } from "react";
import staricon from "assets/staricon.svg";
import exclamation from "assets/exclamation.svg";
import CopyIcon from "assets/copytools.jpg";
import DownloadIcon from "assets/downloadtool.jpg";
import { Tooltip } from "react-tooltip";

export const QualityOutput = ({
  setShowDetailAnalysis,
  analysisResult,
  refDiv,
  setDivHeight,
  isDifficulty,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy");

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);

  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current?.base?.innerText;

      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const downloadCSV = () => {
    const headers = ["Metric", "Score", "Description"];
    const rows = Object.entries(analysisResult)
      .slice(0, -1)
      .map(([key, value]) => {
        const [score, description] = value;
        return [key, score, description];
      });

    const csvContent = [
      headers.join(","),
      ...rows.map((e) => e.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "analysis_result.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div ref={refDiv} className="w-full">
      <div className="flex gap-2 items-center justify-center text-lg font-opensans">
        {analysisResult?.overall_score && (
          <>
            <p className="text-[#858585] font-bold text-sm">Overall Score</p>
            <p className="flex gap-1 items-center text-center text-lg bg-[#16548D] text-white rounded-full px-5 py-1">
              {analysisResult?.overall_score[0]}
              <img src={staricon} alt="staricon" />
            </p>
          </>
        )}
        <p className="font-bold text-[#424242]">Excellent!</p>
      </div>

      <div className="flex flex-col gap-2 w-full mt-4">
        {analysisResult &&
          Object.entries(analysisResult)
            ?.slice(0, -1)
            .map(([key, value], i) => {
              const [score, description] = value;
              return (
                <div key={i} className="bg-[#F0F5FF] p-3 rounded-lg">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => handleToggle(i)}
                  >
                    <p className="flex items-center gap-3 text-[#858585] text-base font-bold font-opensans">
                      <span className="bg-[#B4D5F3] rounded-md px-1 py-2 text-[#424242] text-sm font-bold">
                        {score}
                      </span>
                      {key}
                    </p>
                    <div className="flex gap-4 items-center">
                      <img src={exclamation} alt="exclamation-icon" />
                      <span
                        className={`text-quotetext-200 cursor-pointer ${
                          activeIndex === i
                            ? "rotate-[316deg]"
                            : "rotate-[135deg]"
                        } arrow transition-all duration-200 ease-linear`}
                      ></span>
                    </div>
                  </div>
                  {activeIndex === i && (
                    <p className="mt-2 text-sm font-normal leading-6 text-[#424242] font-opensans">
                      {description}
                    </p>
                  )}
                </div>
              );
            })}
      </div>
      <div className="bg-white p-3 mt-4  rounded-lg  w-full flex items-center justify-between gap-5 absolute right-0 bottom-0">
        <p
          className="text-base font-opensans text-center font-semibold text-[#5B93FF] cursor-pointer"
          onClick={() => setShowDetailAnalysis(true)}
        >
          See detailed analysis
        </p>
        <div className="flex gap-4 ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <button
            data-tooltip-id="download-tooltip"
            data-tooltip-content="Download"
            onClick={downloadCSV}
            className=""
          >
            <img src={DownloadIcon} alt="/downloadicon" />
          </button>
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
        <Tooltip
          id="download-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
    </div>
  );
};
export default QualityOutput;
