
import React from "react";
import { Link } from "gatsby";

const DetailAnalysis = ({ setShowDetailAnalysis, analysisResult }) => {
  const analysisResults = analysisResult;
  const analysisData = Object?.entries(analysisResults).map(([key, value]) => ({
    title: key,
    score: value[0],
    description: value[1],
  }));

  const downloadCSV = () => {
    const csvRows = [
      ["Category", "Analysis", "Score"], 
      ...analysisData.map(({ title, description, score }) => [
        title,
        description,
        score,
      ]),
    ];

    const csvString = csvRows
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "analysis_data.csv");
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-[#F5F5F5]">
      <div className="max-w-7xl mx-auto p-4">
        <button
          className="flex items-center text-lg gap-2 text-[#0A2641] font-opensans font-bold leading-6"
          onClick={() => setShowDetailAnalysis(false)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M23.75 15H6.25" stroke="#0A2641" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 23.75L6.25 15L15 6.25" stroke="#0A2641" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Back
        </button>
        
        <div className="mt-8 lg:flex items-center justify-between">
          <p className="text-[#131313] font-opensans leading-6 font-normal text-lg">
            Detailed analysis of your source text and its translation
          </p>
          <button
            className="border-2 px-4 py-1 flex gap-2 items-center font-semibold border-[#16548D] rounded-xl text-[#16548D]"
            onClick={downloadCSV} 
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M2.5 19.1072C1.83696 19.1072 1.20107 18.8438 0.732233 18.375C0.263392 17.9061 0 17.2703 0 16.6072V13.0358C4.23432e-09 12.7516 0.112882 12.4791 0.313814 12.2782C0.514746 12.0772 0.787268 11.9644 1.07143 11.9644C1.35559 11.9644 1.62811 12.0772 1.82904 12.2782C2.02997 12.4791 2.14286 12.7516 2.14286 13.0358V16.6072C2.14286 16.8044 2.30286 16.9644 2.5 16.9644H17.5C17.5947 16.9644 17.6856 16.9267 17.7525 16.8598C17.8195 16.7928 17.8571 16.7019 17.8571 16.6072V13.0358C17.8571 12.7516 17.97 12.4791 18.171 12.2782C18.3719 12.0772 18.6444 11.9644 18.9286 11.9644C19.2127 11.9644 19.4853 12.0772 19.6862 12.2782C19.8871 12.4791 20 12.7516 20 13.0358V16.6072C20 17.2703 19.7366 17.9061 19.2678 18.375C18.7989 18.8438 18.163 19.1072 17.5 19.1072H2.5Z" fill="#16548D" />
              <path d="M8.92832 10.0914V1.96425C8.92832 1.68009 9.04121 1.40757 9.24214 1.20664C9.44307 1.0057 9.71559 0.892822 9.99975 0.892822C10.2839 0.892822 10.5564 1.0057 10.7574 1.20664C10.9583 1.40757 11.0712 1.68009 11.0712 1.96425V10.0914L13.8855 7.27854C13.9849 7.17911 14.1029 7.10024 14.2328 7.04642C14.3628 6.99261 14.502 6.96492 14.6426 6.96492C14.7832 6.96492 14.9225 6.99261 15.0524 7.04642C15.1823 7.10024 15.3003 7.17911 15.3998 7.27854C15.4992 7.37797 15.5781 7.49601 15.6319 7.62592C15.6857 7.75583 15.7134 7.89506 15.7134 8.03568C15.7134 8.17629 15.6857 8.31553 15.6319 8.44544C15.5781 8.57535 15.4992 8.69339 15.3998 8.79282L10.7569 13.4357C10.556 13.6363 10.2837 13.749 9.99975 13.749C9.71582 13.749 9.4435 13.6363 9.24261 13.4357L4.59975 8.79282C4.50032 8.69339 4.42145 8.57535 4.36764 8.44544C4.31383 8.31553 4.28613 8.17629 4.28613 8.03568C4.28613 7.89506 4.31383 7.75583 4.36764 7.62592C4.42145 7.49601 4.50032 7.37797 4.59975 7.27854C4.69918 7.17911 4.81722 7.10024 4.94713 7.04642C5.07704 6.99261 5.21628 6.96492 5.35689 6.96492C5.49751 6.96492 5.63675 6.99261 5.76666 7.04642C5.89657 7.10024 6.01461 7.17911 6.11404 7.27854L8.92832 10.0914Z" fill="#16548D" />
            </svg>
            Download
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M10.9356 1.53198L5.99956 6.46806L1.06348 1.53198" stroke="#16548D" strokeWidth="1.48082" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        
        <div className="pt-5 overflow-x-scroll">
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-white font-opensans text-base">
                <th className="min-w-[255px] mx-auto bg-[#0A2641] py-2 px-4 border-none">Category</th>
                <th className="bg-[#16548D] min-w-[796px] p-2 border-none">Analysis</th>
                <th className="bg-[#0A2641] p-2 min-w-[128px] border-none">Score</th>
              </tr>
            </thead>
            <tbody>
              {analysisData &&
                analysisData.map((item, index) => (
                  <tr key={index} className="text-base font-opensans">
                    <td className={`${index % 2 === 0 ? "bg-[#BDD3FF]" : "bg-[#E0EBFF]"} p-2 text-start border-none`}>
                      {item.title}
                    </td>
                    <td className={`p-2 text-start ${index % 2 === 0 ? "bg-[#E0EBFF]" : "bg-[#F0F5FF]"} border-none`}>
                      {item.description}
                    </td>
                    <td className={`${index % 2 === 0 ? "bg-[#BDD3FF]" : "bg-[#E0EBFF]"} p-2 text-center border-none`}>
                      {item.score}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        
        <div className="mt-4 bg-[#F0F5FF] p-4 flex items-center justify-between">
          <div>
            <h2 className="font-primary text-[#0A2641] font-bold text-2xl">Need help translating?</h2>
            <p className="font-opensans text-base text-[#424242] leading-6 mt-3">
              If your translation needs improvement, reach out to Tomedes for expert translation, editing, and proofreading services.
            </p>
          </div>
          <Link to="/contactus.php">
            <button className="bg-[#0A2641] text-white rounded-lg px-4 py-2">Contact us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailAnalysis;
